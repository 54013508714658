.community-wrapper {
    $side-bar-size-1400: 315px;
    $side-bar-size-1200: 285px;
    $side-bar-size-992: 215px;
    $side-bar-size-768: 160px;
    $topnav-height: 60px;

    padding-left: 0;
    height: 100%;
    width: 100%;
    background: $marble-white;

    .side-bar {
        display: flex;
        flex-direction: column;
        border-right: 1px solid $pebble-white;
        z-index: 1;

        @media (min-width: 768px) {
            position: absolute;
            top: 0;
            left: $side-bar-size-768;
            width: $side-bar-size-768;
            margin-left: -$side-bar-size-768;
            background-color: $white;
            bottom: 0;
            padding-top: 31px;
            padding-bottom: 40px;
            overflow-y: scroll;
        }

        @media (min-width: 992px) {
            width: $side-bar-size-992;
            left: $side-bar-size-992;
            margin-left: -$side-bar-size-992;
        }

        @media (min-width: 1200px) {
            width: $side-bar-size-1200;
            left: $side-bar-size-1200;
            margin-left: -$side-bar-size-1200;
        }

        @media (min-width: 1400px) {
            width: $side-bar-size-1400;
            left: $side-bar-size-1400;
            margin-left: -$side-bar-size-1400;
        }

        &__community-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: fit-content;
            padding: 15px;
            border-bottom: 1px solid $pebble-white;
            cursor: pointer;
            font-size: 14px;
            line-height: 18px;
            color: $grey-cloud;

            &:first-child {
                border-top: 1px solid $pebble-white;
            }

            &_active {
                background-color: rgba(0, 170, 255, 0.05);
            }

            &_title {
                display: block;

                &_description {
                    font-size: 12px;
                }
            }
        }

        &__arrow {
            width: 27px;
            height: 12px;
        }
    }

    .community-post-view {
        width: 100%;
        height: 100%;
        padding: 37px 0 0 30px;
        overflow-y: scroll;
        position: relative;

        @media (min-width: 768px) {
            padding-left: $side-bar-size-768;
        }

        @media (min-width: 992px) {
            padding-left: $side-bar-size-992;
        }

        @media (min-width: 1200px) {
            padding-left: $side-bar-size-1200;
        }

        @media (min-width: 1400px) {
            padding-left: $side-bar-size-1400;
        }

        h1 {
            font-size: 30px;
            font-weight: 900;
            line-height: 1.2;
            text-transform: none;
            color: $sn-black;
            margin-bottom: 35px;
        }
    }

    .new-community-post {
        display: flex;
        flex-direction: row;
        box-shadow: 0 2px 20px 0 rgba(18, 18, 18, 0.05);
        background: $white;
        padding: 15px;
        margin-bottom: 20px;

        &__image {
            border-radius: 50%;
            height: 40px;
            margin-right: 10px;
        }

        &__submit {
            display: block;
            width: 100%;
            border-radius: 3px;
            border: 1px solid $pebble-white;
            box-shadow: none;
            background-color: $white;
            font-size: 14px;
            line-height: 20px;
            padding: 10px;
            -webkit-appearance: none;
            outline: 0;
            background-image: none;
            color: $sn-black;

            &::-moz-placeholder {
                color: $grey-cloud;
                opacity: 1;
            }

            &:-moz-placeholder {
                color: $grey-cloud;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $grey-cloud;
            }

            &::-webkit-input-placeholder {
                color: $grey-cloud;
            }
        }

        &__button {
            width: 220px;
            height: 40px;
            border-radius: 3px;
            background-color: $sn-blue;
            text-align: center;
            font-size: 14px;
            color: $white;
        }

        &__pin-row {
            margin-left: 10px;
            width: 320px;
        }
    }

    .community-posts-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 1000px;
        margin: auto;
        padding: 0 30px;
    }

    .community-post {
        &-wrapper {
            max-width: 1000px;
            box-shadow: 0 2px 20px 0 rgba(18, 18, 18, 0.05);
            margin: 0 0 10px;
        }

        &-delete-box-wrapper {
            position: relative;

            &:hover {
                .community-post__delete-box {
                    opacity: 1;
                }
            }
        }

        display: flex;
        flex-direction: column;
        background: $white;
        padding: 15px 15px 0;

        &-day-divider {
            margin: 10px 0;
            font-weight: 900;
            color: $sn-black;
            font-size: 14px;
            line-height: 20px;
        }

        &-header {
            display: flex;
            margin-bottom: 10px;

            &__image {
                display: flex;
                border-radius: 50%;
                height: 40px;
            }

            &__wrapper {
                flex-direction: column;
                display: flex;
                align-self: center;
                margin-left: 10px;
            }

            &__name {
                font-size: 14px;
                line-height: 18px;
                color: $sn-black;
                font-weight: 900;
                display: flex;
            }

            &__mentor {
                font-size: 12px;
                color: $grey-cloud;
                margin-left: 10px;

                img {
                    margin-right: 5px;
                }
            }

            &__time {
                font-size: 12px;
                line-height: 1.5;
                color: $sn-black;
            }
        }

        &-text {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.29;
            color: $sn-black;
            margin-bottom: 15px;
            white-space: pre-wrap;
        }

        &-interaction-bar {
            border-top: 1px solid $pebble-white;
            flex-direction: row;
            display: flex;
            justify-content: space-between;
            padding: 15px 0;

            &_comment {
                padding-top: 0;
                padding-bottom: 15px;
                border-top: none;
            }

            &-likes,
            &-comment {
                display: flex;
                user-select: none;
            }

            &-likes {
                cursor: pointer;
                position: relative;

                &__tooltip-holder {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }
            }

            span {
                font-size: 14px;
                font-weight: 900;
                color: $grey-cloud;
                align-self: center;
                margin-left: 6px;
            }
        }

        &-comment {
            display: flex;
            flex-direction: column;
            background: $white;
            border-top: 1px solid $pebble-white;
            padding-top: 15px;
            position: relative;

            &:hover {
                .community-post-comment__comment-delete-box {
                    opacity: 1;
                }
            }

            &__comment-delete-box {
                display: flex;
                flex-direction: column;
                opacity: 0;
                position: absolute;
                right: 0;

                p {
                    font-size: 12px;
                    font-weight: 900;
                }
            }

            &.reply-container {
                padding-left: 25px;
            }
        }

        &-view-comments {
            font-size: 14px;
            font-weight: 900;
            line-height: 1.29;
            color: $grey-cloud;
            padding: 15px 0;
            border-top: 1px solid $pebble-white;
            cursor: pointer;
            user-select: none;
        }

        &-reply-bar {
            display: flex;
            flex-direction: row;
            background: $white;

            &-camera {
                cursor: pointer;
                align-self: center;
                margin: 0 15px;
            }

            &-submit {
                display: block;
                width: 100%;
                height: 42px;
                margin: 10px 15px 10px 0;
                border-radius: 21px;
                border: 1px solid $pebble-white;
                box-shadow: none;
                background-color: $white;
                font-size: 14px;
                line-height: 20px;
                min-height: 2.625rem !important;
                max-height: 10rem !important;
                padding: 10px 20px;
                -webkit-appearance: none;
                outline: 0;
                background-image: none;
                color: $sn-black;

                &::-moz-placeholder {
                    color: $grey-cloud;
                    opacity: 1;
                }

                &:-moz-placeholder {
                    color: $grey-cloud;
                    opacity: 1;
                }

                &:-ms-input-placeholder {
                    color: $grey-cloud;
                }

                &::-webkit-input-placeholder {
                    color: $grey-cloud;
                }
            }
        }

        &-reply-status {
            border-top: 1px solid $pebble-white;
            border-bottom: 1px solid $pebble-white;
            padding: 10px 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            img {
                cursor: pointer;
            }
        }

        &__delete-box {
            display: flex;
            flex-direction: column;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;

            p {
                font-size: 12px;
                font-weight: 900;
            }
        }
    }

    .tooltip .tooltip-inner {
        white-space: pre-line;
        line-height: 1.5;
    }

    .image-preview-holder {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        padding: 2rem;

        a {
            background-color: inherit;
            display: flex;
            align-content: center;
            align-items: baseline;
            padding: 2rem;
        }
    }

    .checkbox-row {
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        &__camera {
            display: flex;
            flex-direction: row;
        }

        &__text {
            padding-left: 8px;
            line-height: 1;
            font-size: 14px;
            font-weight: 400;
            color: $sn-black;
            vertical-align: bottom;
        }
    }
}
