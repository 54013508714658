.no-margin {
    margin: 0 !important;
}

.btn-standard {
    color: white;
    background-color: $sn-blue;
    margin-top: 20px;
    font-size: 16px;
    padding: 11px 18px;
    border: none;
    border-radius: 4px;
    font-weight: 900;
    position: relative; // to handle spinners

    @include transition(all, 0.15s, ease);

    &:focus {
        color: $white;
        outline: none;
    }

    &:hover {
        color: $white;
    }

    &:active:focus {
        outline: none;
    }

    &:disabled {
        color: $sn-black;
        @include box-shadow(0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08));
    }

    &[data-loading="true"] {
        padding-right: 40px;
        cursor: default;
    }

    &[data-loading="true"] .spinner {
        opacity: 1;
    }

    &.btn-standard-white {
        color: $sn-blue;
        background-color: $white;
        text-shadow: none;
    }

    &.btn-standard-white-small {
        @include box-shadow(
            0 0 0 1px rgba(50, 50, 93, 0.1),
            0 2px 5px 0 rgba(50, 50, 93, 0.08),
            0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
            0 1px 2px 0 rgba(0, 0, 0, 0.08),
            0 0 0 0 transparent
        );

        color: $grey-cloud;
        background-color: $white;
        text-shadow: none;
        font-size: 14px;
        text-transform: none;

        &:hover {
            @include box-shadow(
                0 0 0 0.5px rgba(50, 50, 93, 0.17),
                0 2px 5px 0 rgba(50, 50, 93, 0.12),
                0 3px 9px 0 rgba(50, 50, 93, 0.08),
                0 1px 1.5px 0 rgba(0, 0, 0, 0.08),
                0 1px 2px 0 rgba(0, 0, 0, 0.08)
            );
        }

        &:active {
            @include box-shadow(
                0 0 0 0.5px rgba(50, 50, 93, 0.15),
                0 2px 5px 0 rgba(50, 50, 93, 0.08),
                0 1px 1.5px 0 rgba(0, 0, 0, 0.05),
                0 1px 2px 0 rgba(0, 0, 0, 0.06),
                0 0 0 0 transparent
            );
        }
    }

    &.block {
        display: block;
    }

    &.shake {
        -webkit-animation-duration: 0.4s;
        animation-duration: 0.4s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-name: inputShake;
        animation-name: inputShake;
    }
}

.spinner {
    position: relative;
    left: 10px;
    top: 5px;
    display: inline-block;
    width: 20px;
    height: 20px;
    opacity: 0;
    margin-top: -10px;
    background-image: url("/img/spinner.gif");
    background-size: 20px auto;

    @include transition(all, 500ms, cubic-bezier(0.175, 0.885, 0.32, 1.275));

    &.in-button-spinner {
        position: absolute;
        right: 10px;
        left: initial;
        left: inherit;
        top: 50%;
        display: block;
    }
}

.tooltip {
    opacity: 1;
    font-size: 16px;
    line-height: 1.2;

    .tooltip-inner {
        background-color: $opmodalgrey;
        font-size: 16px;
        font-weight: 400;
        color: $opdarkgrey;
        border-radius: 3px;
        padding: 12px 0;
        width: 300px;

        @include box-shadow($boxshadowlight);
    }

    &.bottom {
        .tooltip-arrow {
            border-bottom-color: $opmodalgrey;
        }
    }

    &.top {
        .tooltip-arrow {
            border-top-color: $opmodalgrey;
        }
    }

    &.right {
        .tooltip-arrow {
            border-right-color: $opmodalgrey;
        }
    }

    &.left {
        .tooltip-arrow {
            border-left-color: $opmodalgrey;
        }
    }
}
