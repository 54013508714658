.modal {
    overflow-y: auto;

    .modal-dialog {
        position: absolute;
        left: 0;
        right: 0;
        width: 80%;
        top: 10px;
        height: 90%;

        p {
            color: $sn-black;
            font-size: 18px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: -0.29px;
            text-align: center;

            strong {
                font-weight: 900;
            }
        }

        h1,
        h2,
        h3 {
            color: $sn-black !important;
        }

        h2 {
            font-size: 26px;
            font-weight: 900;
            line-height: 1.38;
            text-align: center;
        }

        @media (max-width: 767px) {
            margin: 10px auto;
            width: 95%;
        }

        @media (max-width: 767px) {
            width: auto;
            top: 0;
        }

        .modal-content {
            text-align: center;
            -webkit-box-shadow: none;
            box-shadow: none;
            border: none;
            border-radius: 0;

            @include box-shadow($boxshadow);

            background-color: $opmodalgrey;
            min-height: 100%;

            .modal-body {
                overflow: visible;

                .close {
                    float: none;
                    color: $opdarkgrey;
                    opacity: 1;
                    text-shadow: none;
                    font-size: 26px;
                    margin-bottom: 8px;

                    &:hover {
                        color: rgb(221, 221, 221);
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }

    &.fade {
        .modal-dialog {
            -webkit-transition: -webkit-transform 0.1s ease-out;
            -moz-transition: -moz-transform 0.1s ease-out;
            -o-transition: -o-transform 0.1s ease-out;
            transition: transform 0.1s ease-out;
        }
    }

    table {
        color: $opdarkgrey;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;

        th,
        td {
            border: 1px solid black;
            padding: 10px;
        }
    }

    form {
        input:not([type="checkbox"]) {
            font-size: 18px;
            height: 37px;

            @include box-shadow(none);

            &:focus {
                @include box-shadow(none);
            }
        }

        textarea {
            font-size: 18px;

            @include box-shadow(none);

            &:focus {
                @include box-shadow(none);
            }
        }
    }

    hr {
        margin-top: 40px;

        @media (max-width: 767px) {
            margin-top: 10px;
        }
    }

    &.set-reminder-modal {
        background-color: rgba(18, 18, 18, 0.8);
        margin-top: -60px;
        height: auto;

        .modal-dialog {
            margin-top: 200px;
            @media (min-width: 768px) {
                width: 350px;
            }

            .modal-content {
                min-height: 0%;

                .crop-area {
                    width: 90%;

                    @media (min-width: 768px) {
                        width: 350px;
                    }
                }
            }
        }
    }

    .ng-hide.ng-hide-animate,
    .ng-leave.ng-leave-active {
        display: none !important;
    }
}

.modal-backdrop {
    background-color: $white;

    &.in {
        opacity: 0.5;
    }

    &.fade {
        @include transition(opacity, 50ms, linear);
    }
}
