.form {
    background-color: $white;
    min-height: 100%;

    h1 {
        font-size: 26px;

        &.large {
            font-size: 35px;
        }
    }

    h2 {
        @media (max-width: 767px) {
            font-size: 28px;
        }
    }

    h4 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 900;
    }

    p {
        font-size: 15px;
        line-height: 1.5;

        &.small {
            font-size: 12px;
            line-height: 1.2;
        }

        &.medium {
            font-size: 17px;
        }

        &.large {
            font-size: 18px;
        }
    }

    label {
        font-size: 15px;

        &.large {
            font-size: 18px;
        }
    }

    .has-error .control-label,
    .has-error .radio-inline,
    .has-error .form-control-feedback,
    .has-error .form-control {
        color: $sn-orange;
    }

    img {
        margin: auto;
    }

    .form-main-container {
        background-color: $white;
        color: $sn-black;
        border-radius: 15px;
        max-width: 850px;
        padding: 20px 150px;

        @include box-shadow(0 0px 30px 0 rgba(0, 0, 0, 0.05));

        a {
            color: $sn-black;

            &:hover {
                text-decoration: none;
            }
        }

        @media (max-width: 991px) {
            padding: 15px 100px;
        }

        @media (max-width: 767px) {
            padding: 15px 5%;
            margin: 0 8px;
        }
    }

    form {
        margin-top: 10px;
    }

    .form-group {
        max-width: 315px;
        margin: 0 auto 15px;
        position: relative;

        .help-text {
            display: none;
            position: absolute;
            left: 100%;
            top: 32px;
            margin-left: 10px;
            white-space: nowrap;
            font-size: 14px;

            .glyphicon {
                margin-right: 5px;
                top: 2px;
            }

            @media (max-width: 767px) {
                top: 100%;
                margin: 3px 0 0;
                text-align: center;
                left: 0;
                right: 0;

                .glyphicon-ok {
                    margin: 0;
                }
            }
        }

        &.radio-buttons .control-label,
        &.form-inline .control-label {
            display: block;
            margin-bottom: 5px;
        }

        &.has-error .help-text.error {
            display: block;
        }

        &.has-success .help-text.ok {
            display: block;
        }

        @media (max-width: 767px) {
            margin-bottom: 26px;
        }

        input[type="text"] + input[type="text"] {
            margin-top: 10px;
        }
    }

    .ng-hide.ng-hide-animate,
    .ng-leave.ng-leave-active {
        display: none !important;
    }

    &.purchase-plan {
        padding-top: 100px;

        @media (max-width: 767px) {
            padding-top: 50px;
        }

        .form-main-container {
            max-width: none;
            padding: 30px 15px;
            margin: 20px 0;
            border-radius: 8px;

            @include box-shadow(0 4px 4px 0 rgba(0, 0, 0, 0.05));

            &.large-container {
                padding: 30px 35px;

                @media (max-width: 991px) {
                    padding: 30px 10px;
                }

                @media (max-width: 767px) {
                    padding: 30px 10px;
                }
            }

            .internal-padding {
                .form-group {
                    max-width: none;
                }
            }

            .form-group {
                a {
                    font-size: 16px;
                    color: $sn-blue;

                    &:hover {
                        color: $sky-blue;
                    }
                }
            }
        }

        hr {
            margin: 10px 0;
            border-top: 1px solid #ededed;
        }

        .internal-padding {
            padding: 0 20px;
        }

        h3 {
            font-weight: 900;
            line-height: 1.428571429;
        }
    }

    &.form-light-grey {
        background-color: $pebble-white;
        padding-top: 100px;

        @media (max-width: 767px) {
            padding-top: 70px;
        }

        h3,
        h4,
        p,
        a {
            color: $sn-black;
        }

        a:hover {
            color: $sn-blue;
        }

        .has-success .control-label {
            color: $sn-green;
        }

        .has-error .control-label {
            color: $sn-orange;
        }
    }
}

select.form-control {
    height: 56px;
    border: 2px dotted #ccc;
    font-size: 18px;
}
