.mentor {
    $side-bar-size-1400: 415px;
    $side-bar-size-1200: 385px;
    $side-bar-size-992: 315px;
    $side-bar-size-768: 260px;
    $topnav-height: 60px;
    $mentor-background: #f1f5f8;

    margin-top: $topnav-height;
    color: $sn-black;
    height: calc(100% - #{$topnav-height});
    background: $marble-white;

    .wrapper {
        padding-left: 0;
        height: 100%;
        width: 100%;
    }

    .page-wrapper {
        width: 100%;
        height: 100%;
        padding: 0;
        background-color: $marble-white;
        overflow-y: scroll;
        position: relative;
    }

    .btn:focus {
        outline: none;
    }

    @media (min-width: 768px) {
        .wrapper {
            padding-left: $side-bar-size-768;
        }

        .page-wrapper {
            padding: 0 10px 10px;
            display: flex;
        }
    }

    @media (min-width: 992px) {
        .wrapper {
            padding-left: $side-bar-size-992;
        }
    }

    @media (min-width: 1200px) {
        .wrapper {
            padding-left: $side-bar-size-1200;
        }
    }

    @media (min-width: 1400px) {
        .wrapper {
            padding-left: $side-bar-size-1400;
        }
    }

    .topnav-brand {
        width: $side-bar-size-768;
        min-width: $side-bar-size-768;

        @media (min-width: 992px) {
            width: $side-bar-size-992;
            min-width: $side-bar-size-992;
        }

        @media (min-width: 1200px) {
            width: $side-bar-size-1200;
            min-width: $side-bar-size-1200;
        }

        @media (min-width: 1400px) {
            width: $side-bar-size-1400;
            min-width: $side-bar-size-1400;
        }
    }

    .dropdown-menu {
        bottom: auto;
        top: auto;
        min-width: auto;

        a {
            overflow: hidden;
        }
    }

    h1 {
        font-size: 30px;
        font-weight: 900;
        color: $sn-black;
        margin-top: 0;
        margin-bottom: 30px;

        @media (max-width: 767px) {
            text-align: center;
        }
    }

    h2 {
        font-size: 24px;
        font-weight: 400;
        margin: 15px 0;

        strong {
            font-weight: 900;
        }

        @media (max-width: 767px) {
            text-align: center;
        }
    }

    h3 {
        font-weight: 400;
        font-size: 28px;

        @media (max-width: 767px) {
            text-align: center;
        }
    }

    p,
    input,
    textarea,
    label,
    button,
    a,
    th,
    td {
        strong {
            font-weight: 900;
        }

        &.small {
            font-size: 80%;
        }
    }

    input,
    select,
    textarea {
        border: none;
        @include box-shadow(
            0 0 0 0.5px rgba(50, 50, 93, 0.27),
            0 0 0 0.5px rgba(50, 151, 211, 0),
            0 0 0 2px rgba(50, 151, 211, 0),
            0 0.5px 1px rgba(0, 0, 0, 0.08)
        );

        &:focus {
            @include box-shadow(
                0 0 0 0.5px rgba(50, 50, 93, 0),
                0 0 0 0.5px rgba(50, 151, 211, 0.2),
                0 0 0 2px rgba(50, 151, 211, 0.25),
                0 0.5px 1px rgba(0, 0, 0, 0.08)
            );
        }

        &[type="checkbox"],
        &[type="radio"] {
            box-shadow: none;
        }
    }

    .has-error .form-control {
        border: 1px solid #a94442;
    }

    .has-success .form-control {
        border: 1px solid $sn-green;
    }

    button {
        white-space: normal;
    }

    .side-nav {
        display: flex;
        flex-direction: column;
        border-right: 1px solid #e2e7ea;

        @media (min-width: 768px) {
            position: fixed;
            top: $topnav-height;
            left: $side-bar-size-768;
            width: $side-bar-size-768;
            margin-left: -$side-bar-size-768;
            background-color: $white;
            bottom: 0;
            padding-top: 20px;
            padding-bottom: 40px;
            overflow-y: scroll;

            > li {
                > a {
                    width: $side-bar-size-768;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-left: 40px;
                    padding-right: 40px;
                    transition: color 0.15s ease-out;
                    color: #b0bac0;
                    text-transform: uppercase;
                    font-size: 14px;

                    &:hover,
                    &:focus {
                        outline: none;
                        color: $sn-blue;
                        background: none;
                    }

                    p {
                        padding-top: 9px;
                        padding-left: 15px;
                        margin-bottom: 3px;
                        cursor: default;
                        text-transform: uppercase;
                        font-size: 14px;
                    }
                }

                .input-group {
                    width: $side-bar-size-768;
                    height: 50px;

                    input {
                        height: 50px;
                        padding-left: 15px;
                        text-align: left;
                        margin: 0;
                    }
                }

                &.active {
                    > a {
                        color: $sn-blue;

                        &.side-icon {
                            color: #999;

                            &:hover {
                                color: $sn-blue;
                            }
                        }
                    }
                }
            }

            &__filter {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 56px;
                padding: 15px;
                border-bottom: 1px solid $pebble-white;
                cursor: pointer;
                font-size: 14px;
                line-height: 18px;
                color: $grey-cloud;

                &_active {
                    @extend .side-nav__filter;
                    background-color: rgba(0, 170, 255, 0.05);
                    color: $sn-black;
                }
            }

            &__arrow {
                width: 27px;
                height: 12px;
            }
        }

        @media (min-width: 992px) {
            width: $side-bar-size-992;
            left: $side-bar-size-992;
            margin-left: -$side-bar-size-992;

            > li {
                > a {
                    width: $side-bar-size-992;
                }

                .input-group {
                    width: $side-bar-size-992;
                }
            }
        }

        @media (min-width: 1200px) {
            width: $side-bar-size-1200;
            left: $side-bar-size-1200;
            margin-left: -$side-bar-size-1200;

            > li {
                > a {
                    width: $side-bar-size-1200;
                }

                .input-group {
                    width: $side-bar-size-1200;
                }
            }
        }

        @media (min-width: 1400px) {
            width: $side-bar-size-1400;
            left: $side-bar-size-1400;
            margin-left: -$side-bar-size-1400;

            > li {
                > a {
                    width: $side-bar-size-1400;
                }

                .input-group {
                    width: $side-bar-size-1400;
                }
            }
        }
    }

    .content-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-width: 1400px;
        padding-top: 10px;
        margin: 0 auto;

        &.users-view {
            height: initial;
            padding-top: 25px;
            width: 100%;

            input {
                max-width: 240px;
            }
        }

        &.user-view {
            max-width: 1170px;
            height: 100%;
            padding-top: 35px;
            width: 100%;

            h3 {
                text-transform: uppercase;
                font-weight: 900;
                font-size: 13px;
                color: $grey-cloud;
                margin-left: 11px;
                margin-bottom: 6px;
            }

            .section {
                background-color: white;
                padding: 15px;
                border: 2px solid #e9eced;
                margin-bottom: 20px;

                img {
                    max-width: 100px;
                    margin: auto;
                }

                h4 {
                    margin-bottom: 10px;
                }

                p:last-child {
                    margin-bottom: 0;
                }
            }

            .form-inline {
                input {
                    max-width: 160px;
                }
            }
        }
    }

    .mentor-split-header {
        display: flex;
        justify-content: space-between;
    }

    .unread-circle {
        left: 5px;
        top: -3px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: relative;
        background: #ff4e67;
        display: inline-block;

        &.group-circle {
            position: absolute;
            top: 22px;
            width: 7px;
            height: 7px;
            z-index: 1;
        }
    }

    .mentor-table,
    .mentor-table_hover {
        max-width: 100%;
        background: $white;
        box-shadow: 0 2px 20px 0 rgba(18, 18, 18, 0.05);

        tr {
            border-bottom: 1px solid $pebble-white;

            th,
            td {
                padding: 15px;
                font-size: 14px;
                font-weight: 900;
                line-height: 1.29;
            }
        }

        thead {
            tr {
                th {
                    color: $sn-black;
                    padding-bottom: 10px;
                }
            }
        }

        tbody {
            tr {
                td {
                    color: $grey-cloud;
                }

                &:first-child {
                    td {
                        padding-top: 20px;
                    }
                }
            }
        }
    }

    .mentor-table_hover {
        tbody {
            tr {
                transition: 0.15s ease-in all;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .table {
        margin-bottom: 50px;

        img {
            height: 23px;
            display: inline-block;
            margin-right: 15px;
            vertical-align: top;
        }

        .form-control {
            height: initial;
            width: initial;
            display: inline-block;

            &.half-size {
                max-width: 175px;
            }
        }

        .glyphicon {
            font-size: 12px;

            &.group-confirm-button {
                cursor: pointer;
                padding-left: 5px;
            }

            &.glyphicon-pencil,
            &.glyph-small {
                font-size: 9px;
                top: -5px;
                right: -3px;
                cursor: pointer;
                margin-right: 2px;
            }

            &.remove-user-button {
                display: none;
                position: absolute;
                left: 0;
                right: 0;
                top: 14px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        span {
            cursor: default;
        }

        tr:hover td .remove-user-button {
            display: inline-block;
        }

        tr.red td {
            color: $sn-orange;
        }

        &.table-hover > tbody > tr:hover > td,
        &.table-hover > tbody > tr:hover > th {
            background-color: #f5f8fa;
        }
    }

    .tooltip {
        opacity: 1;
    }

    .tooltip-inner {
        background-color: #222;
        font-size: 16px;
        font-weight: 400;
        color: $white;
        border-radius: 3px;
        padding: 12px 10px;
        width: initial;
        min-width: 150px;

        @include box-shadow($boxshadowlight);
    }

    .tooltip.bottom {
        .tooltip-arrow {
            border-bottom-color: #f7f8f9;
        }
    }

    .divider {
        height: 1px;
        width: 100%;
        margin: 9px 0;
        overflow: hidden;
        background: #e7eaec;
    }

    .mentor-button,
    .mentor-button_max-width,
    .mentor-button_no-right-margin,
    .mentor-button_right-margin,
    .mentor-button_no-margin,
    .mentor-button_inverted,
    .mentor-button_inverted-border,
    .mentor-button_referrals {
        box-shadow: 0 2px 20px 0 rgba(18, 18, 18, 0.05);
        color: $white;
        background-color: $sn-blue;
        border-radius: 3px;
        min-height: 40px;
        font-size: 14px;
        line-height: 26px;
        padding: 0 12px;
        margin: 5px;
        text-transform: none;
        text-shadow: none;
        font-weight: 400;
        transition: all 0.15s;
        position: relative;
        white-space: nowrap;
        flex: 1;

        &:disabled {
            background: $pebble-white;
        }

        &:active {
            opacity: 0.9;
        }

        &[data-loading="true"] {
            padding-right: 40px;
            cursor: default;
        }

        &[data-loading="true"] .spinner {
            opacity: 1;
        }

        .material-icons,
        .image-icon {
            margin-right: 5px;
            font-size: 20px;
            margin-top: 0;
            position: relative;
        }

        .material-icons {
            top: 5px;
        }
    }

    .mentor-button_inverted,
    .mentor-button_inverted-border {
        background: $white !important;
        color: $grey-cloud;

        &-border,
        &-border_light {
            border: 1px solid $grey-cloud;
        }

        &-border_light {
            border: 1px solid $grey-cloud;
        }
    }

    .mentor-button_max-width {
        flex: 1;
        max-width: 150px;
    }

    .mentor-button_no-left-margin {
        margin-left: 0;
    }

    .mentor-button_no-right-margin {
        margin-right: 0 !important;
    }

    .mentor-button_referrals {
        margin: 0 10px 5px 0;
        width: 234px;
    }

    .mentor-button_no-margin {
        margin: 0;
    }

    .mentor-button_right-margin {
        margin: 0 10px 0 0;
    }

    /* ==== editable-form ==== */
    /* class for single editable element */
    .editable-wrap {
        display: inline-block;
        white-space: pre;
        margin: 0;
    }

    /* remove bottom-margin for bootstrap */
    .editable-wrap .editable-controls,
    .editable-wrap .editable-error {
        margin-bottom: 0;
    }

    /* remove bottom-margin of inputs */
    .editable-wrap .editable-controls > input,
    .editable-wrap .editable-controls > select,
    .editable-wrap .editable-controls > textarea {
        margin-bottom: 0;
    }

    /* keep buttons on the same line */
    .editable-wrap .editable-input {
        display: inline-block;
    }

    .editable-buttons {
        display: inline-block;
        vertical-align: top;
    }

    .editable-buttons button {
        margin-left: 5px;
        height: 25px;
        margin-top: 0;
        padding-top: 1px;
    }

    /* in bootstrap width: 100% => buttons go outside the box */
    .editable-input.editable-has-buttons {
        width: auto;
    }

    /* ==== editable-text ==== */
    /* fix padding issue on typeahead */
    .editable-text {
        white-space: nowrap;
    }

    /* ==== editable-bsdate ==== */
    /* fix padding issue on bsdate popup */
    .editable-bsdate {
        white-space: nowrap;
    }

    /* ==== editable-bstime ==== */
    /* fix padding issue on bstime */
    .editable-bstime {
        white-space: nowrap;
    }

    /* workaround for bootstrap that sets width: 100% and inputs become too wide */
    .editable-bstime .editable-input input[type="text"] {
        width: 46px;
    }

    /* less padding for .well */
    .editable-bstime .well-small {
        margin-bottom: 0;
        padding: 10px;
    }

    /* ==== editable-range ==== */
    .editable-range output {
        display: inline-block;
        min-width: 30px;
        vertical-align: top;
        text-align: center;
    }

    /* ==== editable-color ==== */
    .editable-color input[type="color"] {
        width: 50px;
    }

    /* ==== editable-checkbox ==== */
    /* ==== editable-checklist ==== */
    /* ==== editable-radiolist ==== */
    .editable-checkbox label span,
    .editable-checklist label span,
    .editable-radiolist label span {
        margin-left: 7px;
        margin-right: 10px;
    }

    /* ==== element ==== */
    /* hiding element */
    .editable-hide {
        display: none !important;
    }

    .editable-click,
    a.editable-click {
        text-decoration: none;
        color: #428bca;
        border-bottom: dashed 1px #428bca;
    }

    .editable-click:hover,
    a.editable-click:hover {
        text-decoration: none;
        color: #2a6496;
        border-bottom-color: #2a6496;
    }

    /* editable-empty */
    .editable-empty,
    .editable-empty:hover,
    .editable-empty:focus,
    a.editable-empty,
    a.editable-empty:hover,
    a.editable-empty:focus {
        font-style: italic;
        color: #d14;
        text-decoration: none;
    }

    textarea.notes {
        max-width: 500px;
        width: 100%;
        height: 120px;
        padding: 5px;
    }

    div.consent {
        margin-bottom: 15px;
    }

    div.notes-saving-status {
        color: rgb(120, 120, 120);
    }

    .reminder-section {
        &__button {
            @extend .mentor-button;
            display: flex;
            width: 165px;
            justify-content: center;
            margin: 0;
            margin-top: 10px;
        }
    }

    .calendar {
        .hour {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            margin: 0;
            border-radius: 0;
            font-size: 12px;
            color: $grey-cloud;
        }

        td {
            width: auto;
        }

        table {
            tr {
                td.active.active:hover,
                td.active.active {
                    background-image: none;
                    background-color: $sn-blue;
                }

                td {
                    span.active.active:hover,
                    span.active.active {
                        background-image: none;
                        background-color: $sn-blue;
                    }
                }
            }
        }

        td.day {
            border: solid 1px $pebble-white;
            width: 46px;
            height: 46px;
            margin: 0;
            border-radius: 0;
            font-size: 12px;
            color: $grey-cloud;
        }

        th.dow {
            width: 20px;
            height: 18px;
            font-size: 12px;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: $grey-cloud;
        }

        th.switch {
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: $sn-black;
        }
    }

    .snooze {
        &__text-area {
            width: 100%;
            margin-bottom: 20px;
            height: 100px;
        }
    }

    .checkbox-row {
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        &__item {
            display: flex;
            flex-direction: row;
            padding-right: 15px;

            &_text {
                padding-left: 5px;
            }
        }

        &__mentor-camera {
            align-items: center;
            align-content: center;
            padding-left: 8px;
            cursor: pointer;

            &_text {
                padding-left: 2px;
                line-height: 1;
                font-size: 14px;
                font-weight: 400;
                vertical-align: text-bottom;
            }
        }

        &__checkbox {
            -webkit-appearance: none;
            width: 15px;
            height: 15px;
            border: solid 1px $grey-cloud;
            border-radius: 3px;
            position: relative;
            cursor: pointer;

            &:checked {
                width: 15px;
                height: 15px;
                border-radius: 3px;
                border: solid 1px $grey-cloud;
                background-color: $grey-cloud;
            }

            &:after {
                content: "\2714";
                font-size: 10px;
                left: 2.5px;
                color: $white;
                position: absolute;
            }
        }
    }

    .mentor-task {
        display: flex;
        flex-direction: row;
        background: $white;
        padding: 15px;
        margin: 0 0 10px;
        width: 700px;
        box-shadow: 0 2px 20px 0 rgba(18, 18, 18, 0.05);

        &-day-divider {
            margin: 10px 0;
            font-weight: 900;
            color: $sn-black;
            font-size: 14px;
            line-height: 20px;
        }

        &-user {
            display: flex;
            margin: 0 10px 0 0;

            &__image {
                display: flex;
                border-radius: 50%;
                height: 40px;
            }
        }

        &-row,
        &-row-details {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &-container {
                flex: 1;
            }
        }

        &__dates,
        &__dates_disabled,
        &__dates_bold,
        &__dates_small {
            font-size: 12px;
            line-height: 18px;
            color: $sn-black;

            &-container {
                display: flex;
                width: 50%;
                flex-direction: column;
                align-items: flex-end;
            }
        }

        &__dates_bold {
            font-size: 14px;
            font-weight: 900;
        }

        &__dates_disabled {
            color: $grey-cloud;
            text-decoration-line: line-through;
        }

        &-row-details {
            padding: 5px 0 15px;
        }

        &__message,
        &__message_disabled {
            font-size: 14px;
            line-height: 18px;
            color: $sn-black;
            max-width: 50%;
        }

        &__message_disabled {
            color: $grey-cloud;
            text-decoration-line: line-through;
        }

        &__actions {
            display: flex;
            flex-direction: row;
            align-items: center;

            &_undo {
                font-size: 14px;
                color: $grey-cloud;
                margin: 0 0 0 10px;
                cursor: pointer;
            }

            &_snooze-container {
                display: flex;
                flex: 1;
                justify-content: flex-end;
            }

            &_disabled {
                color: $grey-cloud;
            }
        }

        &__name,
        &__name_disabled {
            font-size: 14px;
            line-height: 18px;
            color: $sn-black;
            font-weight: 900;
        }

        &__name_disabled {
            color: $grey-cloud;
            text-decoration-line: line-through;
        }

        &__category {
            font-weight: 900;

            &_red {
                color: $sn-orange;
            }

            &_cyan {
                color: $sn-blue;
            }

            &_orange {
                color: $sunset;
            }

            &_blue {
                color: $sn-black;
            }

            &_green {
                color: $sn-green;
            }

            &_purple {
                color: $sn-purple;
            }

            &_yellow {
                color: $sn-yellow;
            }

            &_disabled {
                color: $grey-cloud;
                text-decoration-line: line-through;
            }
        }
    }

    .filter-panel {
        width: 350px;
        margin-left: 10px;
        margin-top: 40px;

        &__row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 15px;
        }

        &__button {
            display: flex;
            width: 130px;
            cursor: pointer;
        }

        &__checkbox {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15px;
            height: 15px;
            border-radius: 5px;
            background-color: $white;
            border: 1px solid $grey-cloud;

            &.active {
                background-color: $grey-cloud;
            }

            &-tick {
                color: $white;
                font-size: 9px;
                top: 0;
            }
        }

        &__text {
            margin-left: 7px;
            font-size: 14px;
            line-height: 18px;
            color: $grey-cloud;
        }
    }

    .referral-filter,
    .referral-filter__icon,
    .referral-filter__text {
        cursor: pointer;
    }

    .referral-filter__icon,
    .referral-filter__icon_rotated {
        margin: 0 0 0 3px;
    }

    .referral-filter__icon_rotated {
        -webkit-transform: rotate(180deg); /* Chrome and other webkit browsers */
        -moz-transform: rotate(180deg); /* FF */
        -o-transform: rotate(180deg); /* Opera */
        -ms-transform: rotate(180deg); /* IE9 */
        transform: rotate(180deg); /* W3C compliant browsers */
    }

    .referral-filter__text {
        color: $grey-cloud;
        font-size: 12px;
    }

    .referral-profile {
        &-header {
            color: $sn-black;
            font-size: 30px;
            font-weight: 900;
            line-height: 1.2;
        }

        &-panels-container {
            display: flex;
            flex-direction: row;
        }

        &-personal {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-right: 10px;
        }

        &-medical {
            display: flex;
            flex-direction: column;
            flex: 2;
        }
    }
}
