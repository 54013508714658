@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes inputShake {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    12.5% {
        -webkit-transform: translateX(-6px);
        transform: translateX(-6px);
    }

    37.5% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }

    62.5% {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }

    87.5% {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }

    to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes inputShake {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    12.5% {
        -webkit-transform: translateX(-6px);
        transform: translateX(-6px);
    }

    37.5% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }

    62.5% {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }

    87.5% {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }

    to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@mixin transition($transition-property, $transition-time, $method) {
    -webkit-transition: $transition-property $transition-time $method;
    -moz-transition: $transition-property $transition-time $method;
    -ms-transition: $transition-property $transition-time $method;
    -o-transition: $transition-property $transition-time $method;
    transition: $transition-property $transition-time $method;
}

@mixin transform($transform-property) {
    -webkit-transform: $transform-property;
    -moz-transform: $transform-property;
    -ms-transform: $transform-property;
    -o-transform: $transform-property;
    transform: $transform-property;
}

@mixin animation($delay, $duration, $animation, $iteration-count, $timing-function) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: $iteration-count;
    -webkit-animation-timing-function: $timing-function;
    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: forwards;
    -moz-animation-iteration-count: $iteration-count;
    -moz-animation-timing-function: $timing-function;
    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: forwards;
    animation-iteration-count: $iteration-count;
    animation-timing-function: $timing-function;
}

@mixin box-shadow($box-shadow-property...) {
    -webkit-box-shadow: $box-shadow-property;
    -moz-box-shadow: $box-shadow-property;
    box-shadow: $box-shadow-property;
}

@mixin user-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
