$opred: #ff4e67;
$opmidmidgrey: #b7b7b7;
$opdarkgrey: #666;
$opmodalgrey: #f7f8f9;
$boxshadow: 0 1px 1px 0 rgba(1, 1, 1, 0.3);
$boxshadowlight: 0 1px 1px 0 rgba(1, 1, 1, 0.1);
$white: #fff;

// Second Nature colours

$sn-black: #042d49;
$grey-cloud: #7f929e; // (or SN black 50%)
$stone: #ccd4d8; // (or SN black 20%)
$pebble-white: #e5e9eb; // (or SN black 10%)
$marble-white: #f1f2f3;

$sn-blue: #00b9cd;
$sky-blue: #7fdce6; // (or SN blue 50%)

$sn-orange: #fa4f26;
$sunset: #fca792; // (or SN orange 50%)

$sn-purple: #935ce4;

$sn-green: #00c38c;
$tree-green: #7fe1c5; // (or SN green 50%)

$sn-yellow: #ffc800;
$sun: #ffe37f; // (or SN yellow 50%)
