@tailwind base;
@tailwind components;
@tailwind utilities;

@import "colors";
@import "../libs/bootstrap";
@import "../libs/datetimepicker";
@import "fonts";
@import "mixins";
@import "form";
@import "mentor";
@import "community";
@import "modals";
@import "helper-classes";
@import "sk-spinner";

html {
    font-family: CeraPro, sans-serif;
    font-size: 100%;
    height: 100%;
    width: 100%;
}

body {
    width: 100%;
    height: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    color: $sn-black;
}

h1,
h2,
h3,
h4 {
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 15px;
    -webkit-font-smoothing: antialiased;
}

h1 {
    font-size: 44px;
}

h2 {
    font-size: 34px;
    font-weight: 300;
}

h4 {
    font-size: 22px;
}

p {
    font-size: 17px;
    -webkit-font-smoothing: antialiased;
}

a {
    color: $sn-black;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-font-smoothing: antialiased;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

::-webkit-input-placeholder {
    // WebKit browsers
    color: white;
}

:-moz-placeholder {
    // Mozilla Firefox 4 to 18
    color: white;
}

::-moz-placeholder {
    // Mozilla Firefox 19+
    color: white;
}

:-ms-input-placeholder {
    // Internet Explorer 10+
    color: white;
}

// Hide up and down arrows on input number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// Angular styles
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}

// Dropdown styles
.dropdown {
    display: inline-block;
}

.dropdown-menu {
    top: initial;
    bottom: 100%;
    min-width: 210px;
}

.progress {
    height: 40px;
    background-color: $white;
    border-radius: 0;
    margin-top: 20px;

    &.progress-goals {
        display: flex;
        flex: 1;
        border-radius: 25px;
        height: 10px;
        margin: 0px;
        background-color: $pebble-white;
    }
}

/* Required so that React Modal animates in and out */
// sass-lint:disable class-name-format
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}
